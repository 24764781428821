import { IAdditionalFooterData } from './../../../models/common';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { UploadFileService } from './../../providers/upload-file.service';
import { environment } from 'src/environments/environment';
import { SettingsService } from './../../providers/settings.service';
import { FirebaseAuthService, PURCHASE_PROCESS } from './../../providers/firebase-auth.service';
import { Component, OnInit, ViewEncapsulation, Input, OnDestroy, Output, EventEmitter, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Router,} from '@angular/router';
import { CardsService } from '../../providers/cards.service';
import { CARD_PURCHASE_STATUS, ICard, SUBSCRIPTION_STATUS } from 'src/app/models/card';
// import { ResponseSignInWithApplePlugin } from '@capacitor-community/apple-sign-in';

import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';

import { Toast } from '@capacitor/toast';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { fullscreenPanelAnimation, inOutAnimation } from '../../providers/animations-list';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogData, ConfirmationDialogComponent } from '../dialog/confirmation-dialog.component';
import { UtilityFunctionsService } from '../../providers/utility-functions.service';
import { FOOTER_MODE, NAVBAR_BTN } from 'src/app/models/menu';
import { User } from 'firebase/auth';
import { MobileCommonService } from '../../mobile/mobileCommon.service';
import { PurchaseService } from '../../providers/purchase.service';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    inOutAnimation,
    fullscreenPanelAnimation
],
})

export class NavbarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() footerBarMode: FOOTER_MODE = FOOTER_MODE.DEFAULT;
  @Input() additionalData: IAdditionalFooterData;
  @Input() disableMainActionBtn: boolean = false;
  @Input() navForSavedCollection: boolean;

  @Output() navbarButtonClick = new EventEmitter<NAVBAR_BTN>();
  @Output() footermodeChange = new EventEmitter<FOOTER_MODE>();

  public get notAuthorizedUserBefore(): boolean {
    return this.settingService.getOriginalUidFromLocalstorage() === null;
  }

  public usdzHref: string = 'blank';
  public environment = environment;

  public FOOTER_MODE = FOOTER_MODE;
  public NAVBAR_BTN = NAVBAR_BTN;
  public SUBSCRIPTION_STATUS = SUBSCRIPTION_STATUS;
  public isSplashScreenComponentShown: boolean = false;
  public loaderProgressBarValue: number = 0;
  public showSingInForm: boolean = false;

  public isDraftsShown: boolean = false;
  public isSearchComponentShown: boolean = false;
  public isNavpanelComponentShown: boolean = false;
  public isMobileRenderLimitWarningShown: boolean = false;

  public isNavbarLibraryBtnActive: boolean = false;
  public isCardInPurchaseProcess: boolean = false;
  public isSubscriptionInProcess: boolean = false;


  public firebaseUserSubscription: Subscription;
  public notLoggedSubscription: Subscription;
  public currentCardSubscription: Subscription;
  public cardPurchaseProcessSubscription: Subscription;
  public inAppPurchaseSubscriptionProcessSubscription: Subscription;
  public statusWatcherInFirebaseSubscription: Subscription;
  public mobileServiceSignOutSubscription: Subscription;
  public renderLimitWatcher: Subscription;


  public currentUser: User;
  public currentVisibleCard: ICard;
  // isUserDeleted: boolean;
  latestUpdate: any;
  isUserAuthenticated: boolean;


  constructor(private router: Router,
    public cardService: CardsService,
    public purchaseService: PurchaseService,
    private cdr: ChangeDetectorRef,
    public firebaseAuth: FirebaseAuthService,
    public settingService: SettingsService,
    public userDataService: UserDataService,
    public utilityService: UtilityFunctionsService,
    public dialog: MatDialog,
    public mobileCommonService: MobileCommonService,
    public uploadService: UploadFileService) { }

  async ngOnInit() {
      // Sample getting link to sample USDZ ref
      if(this.firebaseAuth.currentUser) {
        this.isUserAuthenticated = true;
      }

      this.mobileServiceSignOutSubscription = this.mobileCommonService.getSignOutSubject().subscribe(() => {
        this.onLogoutBtnEvent();
      });

      this.cardService.getHrefUsdzSample().then((refUrl)=>{
        this.usdzHref = refUrl
      })
      const footerModeInitial = this.footerBarMode ? this.footerBarMode : FOOTER_MODE.DEFAULT;
      // if(this.firebaseAuth.currentUser === undefined) {
      //   this.footerBarMode = FOOTER_MODE.DEFAULT;
        this.firebaseUserSubscription = this.firebaseAuth.firebaseUser.subscribe(async (x) => {
          this.currentUser = x
          this.isUserAuthenticated = false;
          if(this.currentUser) {
            this.isUserAuthenticated = true;
            this.footerBarMode = footerModeInitial;
            this.isSplashScreenComponentShown = false;
            console.log(this.currentUser.displayName, this.currentUser.email);
          }
        });
      // } else {
      //   this.isSplashScreenComponentShown = false;
      // }

      this.firebaseAuth.getUserUid().then((uid) => {
        if(this.statusWatcherInFirebaseSubscription) this.statusWatcherInFirebaseSubscription.unsubscribe();
        if(this.renderLimitWatcher) this.renderLimitWatcher.unsubscribe();
        this.renderLimitWatcher = this.mobileCommonService.triggerRenderLimitEvent.subscribe(() => {
          this.triggerMobileRenderLimitWarning();
        });
        this.statusWatcherInFirebaseSubscription = this.firebaseAuth.createSubscriptionForStatusChangesInFirebase().subscribe(data => {
          this.latestUpdate = data;
          console.log(`StatusWatcherInFirebaseSubscription Latest Update: ${JSON.stringify(this.latestUpdate)}`);
          this.cardService.setSubForStatusSubscription();
        }, error => {
          console.error('Error:', error);
        });
      });

      this.notLoggedSubscription = this.firebaseAuth.notLoggedUserSubscription.subscribe(() => {
        console.log('notlogged event recieved, showing sing in form;')
        this.showSingInForm = true;
        this.isUserAuthenticated = false;

      })

      this.currentCardSubscription = this.cardService.currentCardSubject.subscribe((card: ICard) => {
        // console.log('set: ', card.cardTitle)
        this.currentVisibleCard = card;
      });

      this.inAppPurchaseSubscriptionProcessSubscription = this.purchaseService.onSubscriptionPurchaseStateChange.subscribe((purchaseState: PURCHASE_PROCESS) => {
        console.log('subscriptionPurchaseProcessSubscription: ', purchaseState )
        switch (purchaseState) {
          case PURCHASE_PROCESS.DEFAULT:
            this.isSubscriptionInProcess = false;
            break;
          case PURCHASE_PROCESS.ERROR:
            this.isSubscriptionInProcess = false;
            break;
          case PURCHASE_PROCESS.PROCESSING:
            this.isSubscriptionInProcess = true;
            break;
          case PURCHASE_PROCESS.SUCCESS:
            this.isSubscriptionInProcess = false;
            break;
          default:
            break;
        }
        this.cdr.detectChanges();
      });

      this.cardPurchaseProcessSubscription = this.purchaseService.onCardPurchaseStateChange.subscribe((purchaseState: PURCHASE_PROCESS) => {
        switch (purchaseState) {
          case PURCHASE_PROCESS.DEFAULT:
            this.isCardInPurchaseProcess = false;
            break;
          case PURCHASE_PROCESS.ERROR:
            this.isCardInPurchaseProcess = false;
            break;
          case PURCHASE_PROCESS.PROCESSING:
            this.isCardInPurchaseProcess = true;
            break;
          case PURCHASE_PROCESS.VERIFICATION:
            this.isCardInPurchaseProcess = true;
            break;
          case PURCHASE_PROCESS.SUCCESS:
            this.isCardInPurchaseProcess = false;
            break;
          default:
            break;
        }
        this.cdr.detectChanges();
      });

  }


  ngOnChanges(changes: SimpleChanges): void {
    // if(changes['additionalData']) {
    //   console.log(changes)
    // }
  }

  public getUserInitials(): string {
    if(this.currentUser.displayName) {
      const matches = this.currentUser.displayName.match(/\b(\w)/g);
      return matches.join('');
    }
    return ''
  }


  public onHideNavpanelEventEmitted(): void {
    this.isNavpanelComponentShown = false;
  }

  public showNavpanel() {
    this.isNavpanelComponentShown = !this.isNavpanelComponentShown;
  }

  // public authorize(user: string, password: string) {
  //   return this.authService.login(user, password)
  //   .pipe(first())
  //   .subscribe({
  //       next: async () => {
  //           // get return url from route parameters or default to '/'
  //           await Toast.show({
  //             text: 'You have successfully logged in!', duration: 'long', position: 'top'
  //           });
  //       },
  //       error: error => {
  //         console.log(error);
  //           // this.error = error;
  //           // this.loading = false;
  //       }
  //   });
  // }


  public onDeleteAccEvent() {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: 'Are you sure?',
        dialogText: `Your account will be deleted permanently`,
        confirmBtntext: 'Yes',
        declineBtnText: 'Back'
      }
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        console.log('delete acc confirmed');


       await this.cardService.disableUserRequest().then(async (resp) => {
            console.log('User request disabled successfully:', resp);
            this.firebaseAuth.clearLocalStorageData();
            this.userDataService.cleanAllUserData();
            this.firebaseAuth.logout();
            // this.isUserDeleted = true;
            this.utilityService.showImportantSnackBar('Your account has been deleted successfully', 500);


        }).catch(async (err) => {
            if(err?.error?.status) {
              console.error('Error disabling user request:', err.error.status);
              this.utilityService.showErrorSnackBar('Error disabling user request:' + err.error.status, 1500);
            } else {
              console.error('Error disabling user request:', err);
              this.utilityService.showErrorSnackBar('Error disabling user request:' + err, 1500);
            }

        });


        // TO SHOW SPLASH SIMULTANEOUSLY
        setTimeout(() => {
        this.footermodeChange.emit(FOOTER_MODE.LIBRARY);
        }, 500);
      }
    });
  }

  public onLogoutBtnEvent() {
    this.firebaseAuth.clearLocalStorageData();
    this.firebaseAuth.logout();
    this.userDataService.cleanAllUserData();
    this.isSplashScreenComponentShown = true;
    // TO SHOW SPLASH SIMULTANEOUSLY
    setTimeout(() => {
    this.footermodeChange.emit(FOOTER_MODE.LIBRARY);
    }, 500);
  }

  public async signOut() {
    this.firebaseAuth.clearLocalStorageData();
    this.firebaseAuth.logout();
    this.userDataService.cleanAllUserData();
    this.isSplashScreenComponentShown = true;
    // this.authService.logout();
    // await Toast.show({
    //   text: 'You have logged out', duration: 'long', position: 'top'
    // });
  }

  public onProfileBtnClick(): void {
    this.footermodeChange.emit(FOOTER_MODE.PROFILE);
    // this.signOut();
    // this.isSplashScreenComponentShown = true;
  }

  public onSplashCloseClicked(): void {
    this.isSplashScreenComponentShown = false;
  }

  public onSplashSignInGoogleClicked(): void {
      // if(this.isUserDeleted) {
      //   this.utilityService.showImportantErrorSnackBar('Your account has been deleted. Please, re-launch app and sing in again', 300);
      //   return
      // }

      if (this.settingService.devicePlatform === 'android') {
        this.signInWithGoogle();
        return;
      } else if(this.settingService.devicePlatform === 'ios') {
        this.signInWithGoogle();
        return;
      } else {
        this.signInWithGoogleWeb();
        return;
      }
  }

  public onSplashSignInAppleClicked() {
    // if(this.isUserDeleted) {
    //   this.utilityService.showImportantErrorSnackBar('Your account has been deleted. Please, re-launch app and sing in again', 300);
    //   return
    // }

    if (this.settingService.devicePlatform === 'ios') {
      this.signInWithApple();
      return;
    } else {
      this.signInWithAppleWeb();
      return
    }
  }

  public async signInWithGoogleWeb() {
    this.firebaseAuth.loginWithGoogle().then(async (token) => {
      this.isSplashScreenComponentShown = false;
      // this.router.navigateByUrl('/home');


    }).catch(async (err) => {
      if(err.code === 'auth/user-disabled') {
        this.utilityService.showImportantErrorSnackBar('User disabled; Please,contact support', 0, 8000);
      } else {
        this.utilityService.showErrorSnackBar(err.code, 4000)
      }
      console.log(err);

    }).finally(()=>{

    });
  }

  public async signInWithAppleWeb() {
    console.log('sign in with apple web')
    const response = await this.firebaseAuth.loginWithAppleWeb()
    this.isSplashScreenComponentShown = false;
    // this.router.navigateByUrl('/home');


  }

  public signInWithApple() {

    // OTHER PACKAGE WITH MORE DATA HERE: https://github.com/pscsrv/cordova-plugin-apple-login  https://github.com/rlfrahm/capacitor-apple-login
    try {
      const options: SignInWithAppleOptions = {
        clientId: environment.appId,
        redirectURI: null,
        scopes: 'email name', // use '' for blank scope not allowing sharing email;
        state: null,
        nonce: null,
      };
      SignInWithApple.authorize(options).then(async (response) => {
        console.log('signInWithAppleData:', response);
        this.firebaseAuth.createFirebaseuser(response.response).then(async (token)=>{
          this.isSplashScreenComponentShown = false;
          // this.router.navigateByUrl('/home');
          // await Toast.show({
          //   text , duration: 'long', position: 'top'
          // });

        }).catch(async (err)=>{
          if(err.code === 'auth/user-disabled') {
            this.utilityService.showImportantErrorSnackBar('User disabled; Please,contact support', 0, 8000);
          }
        })
      }).catch(async (e) => {
        console.log(e)
})
    } catch (e) {
      console.log(e);
    }
  }

  public async signInWithGoogle() {
    const googleUser = await GoogleAuth.signIn();
    this.firebaseAuth.loginWithGoogleNative(googleUser.authentication.idToken)
    .then(async (token) => {
      this.isSplashScreenComponentShown = false;
      // this.router.navigateByUrl('/home');

    }).catch(async (err) => {
      this.utilityService.rollbarError(err);
      await Toast.show({
        text: err , duration: 'long', position: 'top'
      });
    }).finally(()=>{
      // this.isSplashScreenComponentShown = false;
    });

  }


  public triggerMobileRenderLimitWarning(): void {
    this.mobileCommonService.cachedFooterState = this.footerBarMode;
    this.mobileCommonService.cachedRenderLimitNumber = this.firebaseAuth.currentUser.renderLimit;
    this.footerBarMode = FOOTER_MODE.RENDER_LIMIT_WARNING;
    this.isMobileRenderLimitWarningShown = true;
  }


  public onDiscoverBtnClick(event: Event): void {
    event.stopPropagation();
    console.log('discover clicked')
    this.triggerHaptics();
    window.setTimeout(() => {
    this.router.navigateByUrl('/home');
    }, this.settingService.discoverBtnEventDelayTime);
  }

  public onHomeBtnClicked(event?: Event): void {
    this.triggerHaptics();
    setTimeout(() => {
    this.router.navigateByUrl('/');
    }, 50)
  }

  public footernavMockedClick(btn: string): void {
    switch (btn) {
      case 'Browse':
        break;
      case 'Library':
        // TODO: Re-think animation;
        setTimeout(() => {
        this.router.navigateByUrl('/');
        }, 50);
        break;
    }
  }

  public processTotalPrice(price: string): string {
    if(price === '$0.00') return 'Total'
    if(price === '$0.99') return 'Total'
    if(price) return price
    return 'Total';
  }


  public onMoreButtonClicked(): void {
    this.cardService.triggerMoreBtnSubject();
  }

  public onLessButtonClicked(event: Event): void {
    event.stopPropagation();
    this.triggerHaptics();
    this.cardService.triggerLessBtnSubject();
    // this.router.navigateByUrl('/home');
  }

  public navbarBtnClick(btn: NAVBAR_BTN, event?: Event): void {
    console.log('navbarBtnClick:', btn)
      this.triggerHaptics();
      if(event) event.stopPropagation();

      if (this.isMobileRenderLimitWarningShown) {
        if (btn === NAVBAR_BTN.BACK) {
          this.isMobileRenderLimitWarningShown = false;
          this.footerBarMode = this.mobileCommonService.cachedFooterState;
          this.mobileCommonService.setRenderLimitIncreaseResult(false);
        }
        if (btn === NAVBAR_BTN.INCREASE_RENDER_LIMIT) {
          this.isMobileRenderLimitWarningShown = false;
          this.footerBarMode = this.mobileCommonService.cachedFooterState;
          // Increase the render limit
          this.increaseRenderLimit().then((success) => {
            this.mobileCommonService.setRenderLimitIncreaseResult(success);
          });
        }
        return;
      }

      switch (true) {
        case btn === NAVBAR_BTN.SEARCH:
          this.utilityService.showImportantSnackBar('Coming soon', 250, 750);
          break;
        case btn === NAVBAR_BTN.ACCOUNT:
          if(this.isUserAuthenticated) this.footermodeChange.emit(FOOTER_MODE.PROFILE);
          else this.handleUnauthorizedUserActionDetected();
          break;
        case btn === NAVBAR_BTN.BUY:
        case btn === NAVBAR_BTN.ADD:
          if(!this.isUserAuthenticated) {
            this.handleUnauthorizedUserActionDetected();
            break;
          }
          this.navbarButtonClick.emit(btn)
          break;
        default:
          this.utilityService.dissmissAllSnackBars();
          this.navbarButtonClick.emit(btn)
          break;
      }
  }

  public handleUnauthorizedUserActionDetected() {
    this.isSplashScreenComponentShown = true;
  }

  public isAdditionalPaddingAdded(): boolean {
    if(this.additionalData?.selectToggleLabel === 'Cancel') return true
  }
  public getSelectLabelText(): string {
    if(this.additionalData?.selectToggleLabel) return this.additionalData.selectToggleLabel
    else return 'Select All'
  }

  public onNewRenderLimitValueRecieved(newLimit: number): void {
    this.mobileCommonService.cachedRenderLimitNumber = newLimit;
  }

  public isRenderLimitLessThenRequired(): boolean {
    return this.firebaseAuth.currentUser.renderModelsPerCycle + this.uploadService.currentNumberOfUploadingOrRenderingModels >= this.mobileCommonService.cachedRenderLimitNumber;
  }

  private async increaseRenderLimit(): Promise<boolean> {
    try {
      const newLimit = this.mobileCommonService.cachedRenderLimitNumber;
      this.firebaseAuth.currentUser.renderLimit = newLimit;
      await this.cardService.setRenderLimit(this.currentUser.uid, newLimit);
      this.utilityService.showImportantSnackBar('Billing Protection value updated', 0);
      return true;
    } catch (err) {
      console.error(err);
      this.utilityService.showErrorSnackBar('Error updating render limit: ' + err, 4000);
      return false;
    }
  }

  public handleNavbarFileInput(event: any): void {
      const file = (event.target as HTMLInputElement).files.item(0);
      if(file) {
        this.uploadService.requestFileUpload(file);
        // const generatedCardId = this.addUploadCard();
        // console.log('upload click, file selected!')
        // this.uploadButtonClicked.emit({
        //   file,
        //   cardId: generatedCardId
        // });
      }
      event.stopPropagation();
  }

  private triggerHaptics(): void {
    if(this.settingService.devicePlatform === 'ios') {
      Haptics.impact({
        style: ImpactStyle.Light
      });
    }
  }

  ngOnDestroy() {
    if(this.firebaseUserSubscription) this.firebaseUserSubscription.unsubscribe();
    if(this.statusWatcherInFirebaseSubscription) this.statusWatcherInFirebaseSubscription.unsubscribe();
    if(this.mobileServiceSignOutSubscription) this.mobileServiceSignOutSubscription.unsubscribe();
    if(this.renderLimitWatcher) this.renderLimitWatcher.unsubscribe();
   this.notLoggedSubscription.unsubscribe()
   this.currentCardSubscription.unsubscribe()
   this.cardPurchaseProcessSubscription.unsubscribe()
   this.inAppPurchaseSubscriptionProcessSubscription.unsubscribe()

  }


}
