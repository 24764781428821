<app-navbar #navbarRef [footerBarMode]="currentLibraryFooterMode" [additionalData]="libraryAdditionalFooterData" [disableMainActionBtn]="isNoPurchasePossible(collectionDetailsSource)" (navbarButtonClick)="onNavbarBtnClick($event)" (footermodeChange)="onFootermodeChanged($event)"></app-navbar>
<app-see-all-switch #switchPanelRef *ngIf="currentLibraryMode === LIBARY_MODE.PUBLISH_REJECTED_REVIEW" [selectedSwitchBtn]="currentSeeAllSwitchPanelMode" (switchBtnClicked)="onSwitchBtnSeeAllClicked($event)" ></app-see-all-switch>

<app-subscription-splash [@fullscreenPanelAnimation]   *ngIf="isSubscriptionSplashShown" [tryForFreeMode]="!userDataService.isFreeUploadLimitReached && !userDataService.isTryFreeEnabled" ></app-subscription-splash>
<app-rejected-screen [@fullscreenPanelAnimation]  *ngIf="isRejectedScreenShown" [rejectedCard]="cardToEdit" (cancelClicked)="onCancelRejectedScreenClicked()" ></app-rejected-screen>
<div #libraryPageViewRef  class="library-page" [ngClass]="{'transformed' : isNavpanelShown}" >
  <ng-container *ngIf="navbarRef.isUserAuthenticated">
  <div *ngIf="currentLibraryMode === LIBARY_MODE.DEFAULT || currentLibraryMode === LIBARY_MODE.PUBLISH_REJECTED_REVIEW || currentLibraryMode === LIBARY_MODE.SEARCH" [@inOutAnimation] class="search-input-wrapper" [ngClass]="{'firstUseMode' : (!navbarRef.isUserAuthenticated && wasnotAuthorizedBefore()) || (isNoDataShown() && userDataService.savedCollections?.length === 0)}" fxLayout="row" fxLayoutAlign="space-between center" >
    <h1 *ngIf="!isSearchActive" class="library-title">Your Library</h1>
    <app-search #searchRef *ngIf="isSearchActive" (cancelSearchClicked)="onCancelSearchClicked()" ></app-search>
    <button *ngIf="!isSearchActive" class="additional-navigation-button" (click)="onTopNavAdditionalMenuBtnClicked()">
      <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="14" r="1.5" fill="#828282"/>
        <circle cx="14" cy="14" r="1.5" fill="#828282"/>
        <circle cx="19.1426" cy="14" r="1.5" fill="#828282"/>
        <circle cx="14" cy="14" r="10.125" stroke="#828282" stroke-width="1.75"/>
        </svg>
    </button>
  </div>

  <!-- LIBRARY CONTENT: -->

  <div *ngIf="currentLibraryMode === LIBARY_MODE.DEFAULT" class="library-content-area" [@inOutAnimation]>
    <div class="new-user-wrapper" *ngIf="isNoDataShown() && userDataService.savedCollections?.length === 0">
      <h1 class="new-user-title">Your Portfolio right here</h1>
      <h3 class="new-user-desc">Tap Add to upload your model</h3>
    </div>
    <!-- TEMPRORAY LIFEHACK TO MAKE LOAD OF TOP PART OF PAGE AND BOTTOM AT THE SAME TIME; WILL BE REMOVED WHEN WE GET RID OFF MOCK DATA -- lifehack is using special condition in ngif -->
    <!-- <app-card-composite-slider class="upper-class" *ngIf="userDataService.userReviewPublishRejectModels?.length > 0 || userDataService.savedCollections?.length > 0" [publishRejectedReviewsCards]="userDataService.userReviewPublishRejectModels" [sourceCollections]="userDataService.savedCollections" (publishRejectedReviewsGalleryClicked)="onPublishReviewRejectGalleryClicked()" (savedCollectionClicked)="onSavedCollectionClicked($event)" [title]="" ></app-card-composite-slider> -->
    <!-- <app-card-horizontal-slider *ngIf="userDataService.userReviewPublishRejectModels?.length > 0 && userDataService.userDraftCollections" [title]="'Published'" [sourceCards]="userDataService.userReviewPublishRejectModels" (cardClicked)="onPublishReviewRejectModelsCardClicked($event)" (titleClicked)="onSeeAllClicked()" ></app-card-horizontal-slider> -->
    <app-thumbnail-gallery *ngIf="!isNoDataShown()" [notInCollection]="mockDataService.generateEmptyCollection(userDataService.allUserCards)" [sourceCollections]="userDataService.userDraftCollections" [savedCardCollection]="userDataService.savedCollections" [galleryTitle]="" (defaultCollectionClciked)="onPortfolioCollectionClciked()" (customBtnClicked)="onDefaultCollectionOrganizeBtnClick($event)" (collectionClicked)="onDraftCollectionClicked($event)" class="zero-margin last-slider-no-switch-panel" ></app-thumbnail-gallery>
  </div>

  <div *ngIf="currentLibraryMode === LIBARY_MODE.PUBLISH_REJECTED_REVIEW"  class="library-content-area" >
    <ng-container *ngIf="currentSeeAllSwitchPanelMode === SWITCH_PANEL_ITEM.PUBLISHED">
       <app-thumbnail-gallery [sourceCards]="userDataService.reviewAndPublishedCards" [galleryMode]="GALLERY_MODE.COLLECTIONS_NON_COMPOSITE"  (cardClicked)="onPublishReviewRejectModelsCardClicked($event)"  class="zero-margin last-slider-no-switch-panel" ></app-thumbnail-gallery>
    </ng-container>
      <ng-container *ngIf="currentSeeAllSwitchPanelMode === SWITCH_PANEL_ITEM.REJECTED">
        <app-thumbnail-gallery [sourceCards]="userDataService.rejectedUserCards" [galleryMode]="GALLERY_MODE.COLLECTIONS_NON_COMPOSITE"  (cardClicked)="onPublishReviewRejectModelsCardClicked($event)"  class="zero-margin last-slider-no-switch-panel" ></app-thumbnail-gallery>
    </ng-container>

  </div>

  <div *ngIf="currentLibraryMode === LIBARY_MODE.SEARCH" class="library-content-area last-slider-no-switch-panel" [@inOutAnimation]>
    <app-card-horizontal-slider class="padding23" *ngIf="userDataService.userReviewPublishRejectModels?.length > 0" [title]="'Sculptures'" [isSmallSize]="true" [sourceCards]="userDataService.getSavedCards()"></app-card-horizontal-slider>
    <app-card-horizontal-slider class="padding23" *ngIf="userDataService.userReviewPublishRejectModels?.length > 0" [title]="'Models'" [isSmallSize]="true" [sourceCards]="userDataService.userPurchasedCards"></app-card-horizontal-slider>
    <app-card-horizontal-slider class="padding23" *ngIf="userDataService.userReviewPublishRejectModels?.length > 0" [title]="'Historical'" [isSmallSize]="true" [sourceCards]="userDataService.userReviewPublishRejectModels"></app-card-horizontal-slider>
    <app-card-horizontal-slider class="padding23" *ngIf="userDataService.userReviewPublishRejectModels?.length > 0" [title]="'Building'" [isSmallSize]="true" [sourceCards]="userDataService.allUserCards"></app-card-horizontal-slider>
  </div>
</ng-container>
<app-first-use-adv [@inOutAnimation] *ngIf="(!navbarRef.isUserAuthenticated && wasnotAuthorizedBefore()) || (isNoDataShown() && userDataService.savedCollections?.length === 0)"></app-first-use-adv>

</div>

<app-name-change-dialogue [@fullscreenPanelAnimation] *ngIf="isNameChangeDialogueShown" [inputValue]="nameDialogDefaultValue" (valueChanged)="onNameDialogValueChanged($event)" ></app-name-change-dialogue>
<app-collection-select-panel [@fullscreenPanelAnimation] #cardSelectRef  *ngIf="isCollectionSelectShown" [title]="'Move to Collection.'" [sourceCollections]="userDataService.allUserDraftCollections" [userSelectedCards]="[]" (collectionSelected)="onCollectionSelected($event)"></app-collection-select-panel>
<app-card-select-panel [@fullscreenPanelAnimation] #cardSelectRef  *ngIf="isCardSelectShown" [title]="'Select Models.'" [showCardPrice]="isCardPriceShown" [cardsToSelect]="cardSelectData" [userSelectedCards]="[]" (cardSelected)="onCardSelected($event)" (cardSelectSwitchPanelClick)="onCardSelectSwitchPanelClicked($event)"></app-card-select-panel>
<app-navpanel *ngIf="isNavpanelShown"  #navPanelRef [@navpanelAnimation]="navpanelAnimationState" (@navpanelAnimation.done)="onNavpanelAnimationDone($event)"  [navpanelActionItems]="libraryNavpanelItems" (hideNavpanelEvent)="onHideNavpanelEventEmitted()" (navpanelActionClickEvent)="onNavpanelActionItemClicked($event)" (navpanelDestroyEvent)="onNavpanelDestroy()"></app-navpanel>
<app-create-new-collection [@fullscreenPanelAnimation] *ngIf="isCreateNewCollectionShown"></app-create-new-collection>

<app-collection-details #collectionDetailsComponent *ngIf="isCollectionDetailsShown" [@collectionDetailsFadeAnimation]="isCollectionDetailsShown" [filterOptions]="filterCardMode" [sourceCollection]="collectionDetailsSource" [collectionEditOptions]="collectionEditOptions" (collectionDetailsThumbClick)="onCollectionDetailsThumbClick($event)" (controlButtonClick)="onCollectionDetailsControlBtnClick($event)"></app-collection-details>


<app-models-card @modelFeedAnimation #modelCardsFeed class="feed-overlap model-feed" *ngIf="isModelFeedShown" [startPosition]="cardFeedCardStartPosition" [feedIsVisible]="isModelFeedShown" [cardsRaw]="modelCardsMock" [feedMode]="cardFeedMode" (cardPressed)="onModelCardPressed()" (cardClicked)="onModelCardClicked($event)" (actionBtnClicked)="onCardControlBtnClicked($event)"></app-models-card>
